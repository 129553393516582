document.addEventListener('DOMContentLoaded', function () {
    const form = document.querySelector('.agitate_form');
    
    if (form) {
        form.addEventListener('submit', function (event) {
            event.preventDefault(); // Prevent the default form submission
            
            const formData = new FormData(form);
            
            // Create a JSON object from the form data
            let formObject = {};
            formData.forEach((value, key) => {
                formObject[key] = value;
            });

            fetch('/wp-json/custom-forms/v1/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formObject)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                location.href = "/thank-you-for-contacting-us/";
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                alert('Error submitting form');
            });
        });
    }
});

/**
 * AntiSpam: Honeypot, JS Check & Timestamp Check
 */
document.addEventListener('DOMContentLoaded', function() {
    var forms = document.querySelectorAll('form');
    
    forms.forEach(function(form) {
        // Honeypot Field
        var honeypotField = document.createElement('input');
        honeypotField.type = 'text';
        honeypotField.name = 'honeypot_field';
        honeypotField.style.display = 'none';
        honeypotField.tabIndex = -1;
        honeypotField.autocomplete = 'off';
        form.appendChild(honeypotField);

        // Timestamp Field
        var formTimestamp = Date.now();
        var timestampField = document.createElement('input');
        timestampField.type = 'hidden';
        timestampField.name = 'form_timestamp';
        timestampField.value = formTimestamp;
        form.appendChild(timestampField);

        // JavaScript Check Field
        var jsCheckField = document.createElement('input');
        jsCheckField.type = 'hidden';
        jsCheckField.name = 'js_check_field';
        jsCheckField.value = 'js_enabled';
        form.appendChild(jsCheckField);
    });
});