$(".nav-toggle").click(function () {
  console.log("test");
  $("body").toggleClass("open");
  $(".nav-toggle").toggleClass("active");
  var el = $(this);
  if ($(el).hasClass("light")) {
    el.removeClass("light");
  } else {
    window.setTimeout(function () {
      el.toggleClass("light");
    }, 600);
  }
});

$("#mobile-menu li a").click(function () {
  $("body").toggleClass("open");
  $(".nav-toggle").toggleClass("active");
  var el = $(".nav-toggle");
  if ($(el).hasClass("light")) {
    el.removeClass("light");
  } else {
    window.setTimeout(function () {
      el.toggleClass("light");
    }, 600);
  }
});

//mobile menu balls

jQuery(document).ready(function ($) {
  if (screen.width < 992) {
    const numOfBall = 5;
    const minBallSize = 20;
    const maxBallSize = 90;
    const boxWidth = 350;
    const boxHeight = 650;
    const boxBorderSize = 5;
    const speed = 190; // Milliseconds
    const color = ["#1f1e1e", "#1b1b1b", "#252323"];
    var posX = [];
    var posY = [];
    var maxX = [];
    var maxY = [];
    var goUp = [];
    var goLeft = [];
    var moveX = [];
    var moveY = [];

    var box = "#balls";

    //document.body.appendChild(box);
    const circle = document.getElementsByClassName("round");

    // -- Funtions -- //
    function getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    }

    function frame(c, i) {
      // Move Y axis
      if (goUp[i]) {
        posY[i] -= moveY[i];
        c.style.top = posY[i] + "px";
        if (posY[i] < 0) {
          posY[i] = 0;
          goUp[i] = false;
        }
      } else {
        posY[i] += moveY[i];
        c.style.top = posY[i] + "px";
        if (posY[i] > maxY[i]) {
          posY[i] = maxY[i] - getRndInteger(1, 6 + 1);
          goUp[i] = true;
        }
      }

      // Move X axis
      if (goLeft[i]) {
        posX[i] -= moveX[i];
        c.style.left = posX[i] + "px";
        if (posX[i] < 0) {
          posX[i] = 0;
          goLeft[i] = false;
        }
      } else {
        posX[i] += moveX[i];
        c.style.left = posX[i] + "px";
        if (posX[i] > maxX[i]) {
          posX[i] = maxX[i] - getRndInteger(1, 6 + 1);
          goLeft[i] = true;
        }
      }
    }

    function init() {
      for (let i = 0; i < numOfBall; i++) {
        // Random Ball Size
        let ballSize = getRndInteger(minBallSize, maxBallSize + 1);
        circle[i].style.width = ballSize + "px";
        circle[i].style.height = ballSize + "px";

        // Random Color
        let colorId = getRndInteger(0, color.length + 1);
        circle[i].style.background = color[colorId];

        // Random Speed
        moveX[i] = getRndInteger(1, 8 + 1);
        moveY[i] = getRndInteger(1, 8 + 1);

        // Calulate MaxX and MaxY
        maxX[i] = boxWidth - boxBorderSize * 2 - ballSize;
        maxY[i] = boxHeight - boxBorderSize * 2 - ballSize;

        // Random Positon
        posX[i] = getRndInteger(0, maxX[i]);
        posY[i] = getRndInteger(0, maxY[i]);
        goUp[i] = getRndInteger(0, 1 + 1);
        goLeft[i] = getRndInteger(0, 1 + 1);

        // Smooth Transition
        circle[i].style.transition = "all linear " + speed + "ms";

        // Move the Balls
        setInterval(function () {
          frame(circle[i], i);
        }, speed);
      }

      // Check variables
      //console.log(circle);
      //console.log(posX);
      //console.log(posY);
      //console.log(goUp);
      //console.log(goLeft);
    }

    init();
  }
});


/**
 * Service Drop Down
 */
// Select the elements
var servicesDropdown = document.querySelector('.services_dropdown');
var submenu = document.querySelector('.sub-menu');
var menuItems = document.querySelectorAll('.menu-item-has-children');

// Clear current contents of submenu
submenu.innerHTML = '';

// Append the contents of .services_dropdown after .submenu
var clonedDropdown = servicesDropdown.cloneNode(true);
submenu.parentNode.insertBefore(clonedDropdown, submenu.nextSibling);

// Remove the original .services_dropdown
servicesDropdown.remove();

// Function to add the 'active' class
function addActiveClass() {
    menuItems.forEach(function(menuItem) {
        menuItem.classList.add('active');
    });
}

// Function to remove the 'active' class
function removeActiveClass() {
    menuItems.forEach(function(menuItem) {
        menuItem.classList.remove('active');
    });
}

// Add 'mouseover' and 'mouseout' event listeners for each menu item
menuItems.forEach(function(menuItem) {
    menuItem.addEventListener('mouseover', addActiveClass);
    menuItem.addEventListener('mouseout', removeActiveClass);
});

// Add 'mouseover' and 'mouseout' event listeners for the cloned .services_dropdown
if (clonedDropdown) {
    clonedDropdown.addEventListener('mouseover', addActiveClass);
    clonedDropdown.addEventListener('mouseout', removeActiveClass);
}

// Get all the links and images
const serviceLinks = document.querySelectorAll('.services_dropdown_list a');
const serviceImages = document.querySelectorAll('.services_dropdown img');

// Function to handle the hover effect
serviceLinks.forEach(link => {
    link.addEventListener('mouseenter', () => {
        // Get the data-service value from the hovered link
        const service = link.getAttribute('data-service');

        // Loop through all images
        serviceImages.forEach(img => {
            // Check if the image matches the hovered service link
            if (img.getAttribute('data-service') === service) {
                img.classList.add('active'); // Show the matching image
            } else {
                img.classList.remove('active'); // Hide others
            }
        });
    });
});
