document.addEventListener("DOMContentLoaded", function () {
  const projects = document.querySelector(".projects");
  if (!projects) return;

  let offset = getOffset(); // Calculate once at start

  function getOffset() {
    return window.innerWidth > 768 ? 900 : 400; // 900px for desktop, 400px for mobile
  }

  function onScroll() {
    const rect = projects.getBoundingClientRect();

    if (rect.top <= offset && rect.bottom >= 0) {
      projects.classList.add("change-bg");
    } else {
      projects.classList.remove("change-bg");
    }
  }

  // Throttle the scroll event
  function throttle(func, limit) {
    let inThrottle;
    return function () {
      if (!inThrottle) {
        func.apply(this, arguments);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }

  // Debounce the resize event
  function debounce(func, delay) {
    let timeout;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, arguments), delay);
    };
  }

  // Event listeners
  window.addEventListener("scroll", throttle(onScroll, 100), { passive: true });
  window.addEventListener(
    "resize",
    debounce(() => {
      offset = getOffset(); // Recalculate offset on resize
      onScroll(); // Trigger a check on resize
    }, 200)
  );

  // Initial check in case the element is already in view on load
  onScroll();
});