document.addEventListener("DOMContentLoaded", function () {
    const serviceHero = document.querySelectorAll(".service_hero_block");
    if(serviceHero.length > 0){
      const dots = document.querySelectorAll("svg.dot");
      const container = document.querySelector(".service_hero_block .container");
      let mouse = { x: 0, y: 0 };
      const magnet = 2000; // Strength of repulsion from the mouse
  
      // Track the mouse position
      document.addEventListener("mousemove", function (e) {
          const rect = container.getBoundingClientRect();
          mouse.x = e.clientX - rect.left; // Mouse position relative to the container
          mouse.y = e.clientY - rect.top;
      });
  
      // Set initial home positions for each dot
      dots.forEach((dot) => {
          const rect = dot.getBoundingClientRect();
          // Store home positions relative to the container
          dot.dataset.homex = rect.left - container.getBoundingClientRect().left;
          dot.dataset.homey = rect.top - container.getBoundingClientRect().top;
          dot.style.position = "absolute"; // Ensure the dots are positioned absolutely
          dot.style.left = `${dot.dataset.homex}px`;
          dot.style.top = `${dot.dataset.homey}px`;
      });
  
      // Main animation loop
      function animateDots() {
          dots.forEach((dot) => {
              let homex = parseFloat(dot.dataset.homex);
              let homey = parseFloat(dot.dataset.homey);
              let x0 = parseFloat(dot.style.left);
              let y0 = parseFloat(dot.style.top);
              let distancex = mouse.x - x0;
              let distancey = mouse.y - y0;
              let distance = Math.sqrt(distancex * distancex + distancey * distancey);
  
              if (distance < 300) { // Only move the dot if the mouse is within a certain range
                  // Repulsion from mouse
                  let powerx = x0 - ((distancex / distance) * magnet) / distance;
                  let powery = y0 - ((distancey / distance) * magnet) / distance;
  
                  // Force back to home
                  let forcex = (homex - x0) / 20;
                  let forcey = (homey - y0) / 20;
  
                  // Calculate new positions with boundaries
                  let newX = Math.max(0, Math.min(container.clientWidth - dot.clientWidth, powerx + forcex));
                  let newY = Math.max(0, Math.min(container.clientHeight - dot.clientHeight, powery + forcey));
  
                  // Apply new positions
                  dot.style.left = `${newX}px`;
                  dot.style.top = `${newY}px`;
              }
          });
  
          // Keep the animation going
          requestAnimationFrame(animateDots);
      }
  
      // Start the animation loop
      animateDots();
  
    }
  });