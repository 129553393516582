jQuery(document).ready(function ($) {
  // Use .one() to ensure the hover event is handled only once
  $(".magnetic").one("mouseenter", function () {
    // Check if the script is already loaded to avoid loading it multiple times
    if (!window.customScriptLoaded) {
      var scriptUrl =
        "/wp-content/themes/agitate/assets/js/dependants/tween-max.min.js"; // Change this to the actual path of your JS file

      $.getScript(scriptUrl, function () {
        // Script is loaded and executed, you can run any callback here if needed
        //console.log("Script loaded successfully.");

        // Set a flag to indicate the script is loaded
        window.customScriptLoaded = true;

        // Initialize magnetic buttons here
        initializeMagneticButtons();
      });
    }
  });

  function initializeMagneticButtons() {
    /*
     * Magnetic Buttons
     */
    var magnets = $(".magnetic"); // Using jQuery for consistency
    var strength = 50;

    magnets.each(function () {
      var magnet = $(this); // Current element in the loop

      magnet.on("mousemove", function (event) {
        moveMagnet(event, magnet);
      });

      magnet.on("mouseout", function (event) {
        TweenMax.to(this, 1, { x: 0, y: 0, ease: Power4.easeOut });
      });
    });

    function moveMagnet(event, magnetButton) {
      var bounding = magnetButton[0].getBoundingClientRect();

      TweenMax.to(magnetButton, 1, {
        x: ((event.clientX - bounding.left) / bounding.width - 0.5) * strength,
        y: ((event.clientY - bounding.top) / bounding.height - 0.5) * strength,
        ease: Power4.easeOut,
      });
    }
  }
});
