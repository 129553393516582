/*
 * Full screen contact pop out
 */
jQuery(document).ready(function ($) {
  // Check if .fullscreen-contact exists
  if ($(".fullscreen-contact").length) {
    // Hover effects for the circle
    $(".circle").hover(
      function () {
        $(".hovered").show();
      },
      function () {
        $(".hovered").hide();
      }
    );

    // Pop-up background toggle
    const $body = $("body");

    const toggleOffcanvas = () => {
      $body.toggleClass("has-offcanvas");
    };

    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    var piTwo = Math.PI * 2;

    var vw = (canvas.width = window.innerWidth);
    var vh = (canvas.height = window.innerHeight);

    var covering = false;
    var isActive = false;

    window.addEventListener("resize", onResize);

    $(".circle").on("click touchstart", loadTween);

    // Function to load TweenMax script dynamically
    function loadTween(event) {
      console.log("Click registered");
      // Check if the script is already loaded
      if (!window.customScriptLoaded) {
        var scriptUrl =
          "/wp-content/themes/agitate/assets/js/dependants/tween-max.min.js"; // Change this to the actual path of your JS file

        $.getScript(scriptUrl, function () {
          console.log("TweenMax script loaded successfully.");
          window.customScriptLoaded = true;

          // Once TweenMax is loaded, run the ripple and toggle functions
          runAfterTweenLoaded(event);
        });
      } else {
        // If TweenMax is already loaded, run the ripple and toggle functions immediately
        runAfterTweenLoaded(event);
      }
    }

    // Function to execute after TweenMax is loaded
    function runAfterTweenLoaded(event) {
      console.log("Running after TweenMax is loaded");
      $(".circle").off("click touchstart", runAfterTweenLoaded); // Ensure the functions only run once per click

      createRipple(event);
      toggleOffcanvas();
      $("#canvas__container").toggle();
    }

    $(".toggle-circle").on("click touchstart", createRipple);
    $(".toggle-circle").on("click touchstart", toggleOffcanvas);
    $(".toggle-circle").click(function () {
      $("#canvas__container").toggle();
    });

    // Function to create ripple effect
    function createRipple(event) {
      if (isActive) return;

      isActive = true;
      covering = !covering;

      var x = event.clientX;
      var y = event.clientY;

      var dx = x < vw / 2 ? vw - x : x;
      var dy = y < vh / 2 ? vh - y : y;

      var radius = Math.sqrt(dx * dx + dy * dy);
      var alpha = covering ? 0 : 1;

      var ripple = {
        alpha: alpha,
        radius: 0,
        x: x,
        y: y,
      };

      TweenMax.to(ripple, 0.75, {
        alpha: 1,
        radius: radius,
        ease: Power4.easeIn,
        onUpdate: drawRipple.bind(ripple),
        onComplete: setInactive,
      });
    }

    // Function to draw the ripple effect
    function drawRipple() {
      ctx.clearRect(0, 0, vw, vh);
      ctx.globalCompositeOperation = "source-over";

      if (!covering) {
        ctx.beginPath();
        ctx.rect(0, 0, vw, vh);
        ctx.fillStyle = "rgba(255,0,66,1)";
        ctx.fill();

        ctx.globalCompositeOperation = "destination-out";
      }

      ctx.beginPath();
      ctx.arc(this.x, this.y, this.radius, 0, piTwo, false);
      ctx.fillStyle = "rgba(255,0,66," + this.alpha + ")";
      ctx.fill();
    }

    // Function to reset the active state
    function setInactive() {
      isActive = false;
    }

    // Function to handle window resize
    function onResize() {
      vw = canvas.width = window.innerWidth;
      vh = canvas.height = window.innerHeight;
    }
  }
});