// Function to dynamically load the Swiper CSS and JS files
function loadSwiperAssets() {
    // Prevent multiple loads
    if (window.swiperLoaded) return;
    window.swiperLoaded = true; // Flag to check if already loaded

    // Load Swiper CSS
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.css';
    document.head.appendChild(link);

    // Load Swiper JS
    var script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.js';
    script.onload = function() {
        // Swiper initialized here if necessary
        //console.log('Swiper assets loaded');
        initializeSwiper();
    };
    document.body.appendChild(script);
}

// Swiper initialization function
function initializeSwiper() {
    var swiper = new Swiper('.swiper-container', {
        loop: true,
        slidesPerView: 3, // Show 3 slides at a time
        spaceBetween: 20, // Adjust space between slides (in pixels)
        autoplay: {
            delay: 6000,
        },
        pagination: false,
        navigation: false,
    
        breakpoints: {
            // When window width is >= 0px (mobile view)
            100: {
                slidesPerView: 1, // Show 1 slide on small screens
                spaceBetween: 10,
            },
            // When window width is >= 320px (mobile view)
            320: {
                slidesPerView: 1, // Show 1 slide on small screens
                spaceBetween: 10,
            },
            // When window width is >= 768px (tablet view)
            768: {
                slidesPerView: 2, // Show 2 slides on medium screens
                spaceBetween: 20,
            },
            // When window width is >= 1024px (desktop view)
            1024: {
                slidesPerView: 3, // Show 3 slides on larger screens
                spaceBetween: 20,
            },
        },
    });

    var swiper = new Swiper('.logo-slider-swiper', {
        loop: true,  // Enable looping
        slidesPerView: 'auto',  // Automatically fit the number of logos in view
        spaceBetween: 30,  // Adjust space between logos
        speed: 4000,  // Speed of the transition (5 seconds for a full loop)
        autoplay: {
            delay: 0, // No delay, continuous scrolling
            disableOnInteraction: false, // Keep scrolling even after interaction
        },
        allowTouchMove: false,  // Disable dragging/swiping for continuous effect
        freeMode: true,  // Continuous scrolling without snapping to slides
        freeModeMomentum: false,  // Disable momentum for constant speed
        breakpoints: {
            320: { slidesPerView: 2, spaceBetween: 20 },
            640: { slidesPerView: 3, spaceBetween: 30 },
            1024: { slidesPerView: 5, spaceBetween: 40 }
        }
    });
}

// Event listener for user interaction (scroll or click)
function attachInteractionListeners() {
    document.addEventListener('scroll', loadSwiperAssets, { once: true });
    document.addEventListener('click', loadSwiperAssets, { once: true });
}

// Ensure DOM is fully loaded before attaching interaction listeners
document.addEventListener('DOMContentLoaded', attachInteractionListeners);