document.addEventListener('DOMContentLoaded', function () {
  var accordionButtons = document.querySelectorAll('.accordion-button');

  accordionButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      var expanded = this.getAttribute('aria-expanded') === 'true';
      var answerId = this.getAttribute('aria-controls');
      var answer = document.getElementById(answerId);
      var accordionItem = this.closest('.accordion_item');

      // Close all open accordions and remove 'active' class
      accordionButtons.forEach(function (btn) {
        btn.setAttribute('aria-expanded', 'false');
        var ansId = btn.getAttribute('aria-controls');
        var ans = document.getElementById(ansId);
        ans.hidden = true;

        var item = btn.closest('.accordion_item');
        if (item) {
          item.classList.remove('active');
        }
      });

      // Toggle the clicked accordion
      if (!expanded) {
        this.setAttribute('aria-expanded', 'true');
        answer.hidden = false;

        // Add 'active' class to the clicked accordion item
        if (accordionItem) {
          accordionItem.classList.add('active');
        }
      }
    });
  });
});