// Function to calculate the distance of an element from the top of the page
function getDistanceFromTop(element) {
  var yPos = 0;
  while (element) {
    yPos = yPos + element.offsetTop;
    element = element.offsetParent; // Use offsetParent instead of parent
  }
  return yPos;
}

// Function to handle revealing elements on scroll
function revealOnScroll() {
  var reachedHeight = window.pageYOffset + window.innerHeight;
  var containers = document.querySelectorAll(
    ".reveal-on-scroll:not(.animated)"
  );

  containers.forEach(function (container) {
    var containerPosition = getDistanceFromTop(container);
    if (reachedHeight >= containerPosition) {
      if (container.dataset.delay) {
        var delay = parseInt(container.dataset.delay);
        window.setTimeout(function () {
          if (!container.classList.contains("animated")) {
            container.classList.add("animated");
          }
        }, delay);
      } else {
        if (!container.classList.contains("animated")) {
          container.classList.add("animated");
        }
      }
    }
  });
}

// Function to check if the class exists and bind the event listeners
function initRevealOnScroll() {
  if (document.querySelector(".reveal-on-scroll")) {
    // Add scroll event listener to trigger revealOnScroll
    window.addEventListener("scroll", revealOnScroll);
    // Add touchmove event listener for mobile devices
    window.addEventListener("touchmove", revealOnScroll);
  }
}

// Initialize the event listeners once the DOM is fully loaded
document.addEventListener("DOMContentLoaded", function () {
  initRevealOnScroll();
});
