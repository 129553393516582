let mouse = { x: 0, y: 0 };

let homex = 0;
let homey = 0;
let forcex = 0;
let forcey = 0;
let magnet = 2000;

// Track mouse position
document.addEventListener("mousemove", function (e) {
  mouse = { x: e.pageX, y: e.pageY };
});

// Handle elements with class 'ball'
document.querySelectorAll(".ball").forEach(function (el, index) {
  el.dataset.homex = parseInt(el.offsetLeft);
  el.dataset.homey = parseInt(el.offsetTop);
});

document.querySelectorAll(".ball").forEach(function(el) {
  el.style.position = "absolute";
});

setInterval(function () {
  document.querySelectorAll(".ball").forEach(function (el, index) {
    let x0 = el.getBoundingClientRect().left + window.pageXOffset;
    let y0 = el.getBoundingClientRect().top + window.pageYOffset;
    let x1 = mouse.x;
    let y1 = mouse.y;
    let distancex = x1 - x0;
    let distancey = y1 - y0;

    let distance = Math.sqrt(distancex * distancex + distancey * distancey);

    let powerx = x0 - ((distancex / distance) * magnet) / distance;
    let powery = y0 - ((distancey / distance) * magnet) / distance;

    let homex = el.dataset.homex;
    let homey = el.dataset.homey;

    forcex = (forcex + (homex - x0) / 2) / 2.1;
    forcey = (forcey + (homey - y0) / 2) / 2.1;

    el.style.left = `${powerx + forcex}px`;
    el.style.top = `${powery + forcey}px`;
  });
}, 15);