/**
 * This is for the "Discuss a Project" CTA used at bottom of pages
 */
/**
 * Disucss a Project CTA
 * .box (red circle) anchors to mouse cursor and follows it whilst cursor is inside the div
 * CSS based on a class pulls blob back to initial position on div leave
 */
jQuery(document).ready(function ($) {
  if ($(".large-cta").length) {
    /*
     * Large CTA - Follow Mouse
     */
    var enableMove = false;
    const $box = $(".large-cta .box");

    $(document).mousemove(function (e) {
      if (enableMove == true) {
        const rect = $(".large-cta")[0].getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;
        $box.css({
          left: mouseX - $box.width() / 2,
          top: mouseY - $box.height() / 2,
          transition: "none",
        });
      } else {
        $(".box").css({
          transition: "all 1s ease-out",
        });
      }
    });

    $(".large-cta").on("mouseenter mouseleave", function (e) {
      if (e.type == "mouseenter") {
        $(".large-cta").removeClass("notactive");
        enableMove = true;
      } else {
        console.log("left area");
        enableMovement = false;
        $(".large-cta").addClass("notactive");
      }
    });

    $(".hovered").hide();

    if (screen.width < 768) {
      $(".hovered").show();
    }

    $(".cta").hover(
      function () {
        $(".hovered").show();
        $(".circle").css({
          transform: "scale(1.2)",
          transition: "transform 1s ease-in",
        });
      },
      function () {
        $(".hovered").hide();
        $(".circle").css({
          transform: "unset",
          transition: "all 2s ease-in",
        });
      }
    );

    $(".cta").click(function () {
      window.location = "/contact-us/";
    });
  }
});
